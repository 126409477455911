<template>
  <div class="campus-page">
    <el-breadcrumb separator-class="el-icon-right">
      <el-breadcrumb-item :to="{ path: '/admin/dashboard' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内容管理</el-breadcrumb-item>
      <el-breadcrumb-item>校区管理</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="page-content">
      <div class="content-header">
        <el-button type="primary" @click="addCampus">添加校区</el-button>
      </div>
      <div class="talbe-area">
        <el-table :data="pageData" border>
          <el-table-column fixed prop="id" label="ID" width="70"></el-table-column>
          <el-table-column prop="m_id" label="唯一ID" width="120"></el-table-column>
          <el-table-column prop="title" label="校区名称"></el-table-column>
          <el-table-column prop="is_change" label="跟随点击切换背景图">
            <template slot-scope="scope">
              <el-tag type="warning" effect="plain" v-if="scope.row.is_change === 1">否</el-tag>
              <el-tag type="success" effect="plain" v-if="scope.row.is_change === 2">是</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="block_num" label="首页显示模块数量"></el-table-column>
          <el-table-column prop="watermark" label="全局水印" width="120">
            <template slot-scope="scope">
              <el-tag type="success" effect="plain" v-if="scope.row.watermark">开启</el-tag>
              <el-tag type="warning" effect="plain" v-if="!scope.row.watermark">关闭</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="status" label="校区状态" width="120">
            <template slot-scope="scope">
              <el-tag type="success" effect="dark" v-if="scope.row.status === 1">正常</el-tag>
              <el-tag type="warning" effect="dark" v-if="scope.row.status === 2">关闭</el-tag>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="130">
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="edit(scope.row.m_id)">编辑</el-button>
              <el-button type="text" size="small" @click="del(scope.row.m_id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Campus',
  data () {
    return {
      pageData: []
    }
  },
  created () {
    this.getPageData()
  },
  methods: {
    async getPageData () {
      const { data: res } = await this.$http.get('/campus-list')
      if (res.code === 200) {
        this.pageData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    addCampus () {
      this.$router.push('/admin/content/campus/add')
    },
    edit (mid) {
      this.$router.push('/admin/content/campus/edit/' + mid)
    },
    async del (mid) {
      const { data: res } = await this.$http.delete('/campus', { mid: mid })
      if (res.code === 200) {
        this.$message.success(res.msg)
        await this.getPageData()
      } else {
        this.$message.error(res.msg)
      }
    }
  }
}
</script>

<style scoped>
  .talbe-area{
    margin-top: 30px;
  }
</style>
